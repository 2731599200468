import { Link } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
import { Global, css } from "@emotion/react"
import * as styles from '../styles/contact.module.css'


export default function Contact() {
  return (
    <Layout logo='contact'>
      <Global
        styles={css`
        * {
          background: #756729;
          color: #b3b68c;
        }
        nav a {
          color: #b3b68c;
        }
        `}
      />
      <div>
        <form method="post" action="#">
          <label>
            Name
            <input type="text" name="name" id="name" />
          </label>
          <label>
            Email
            <input type="email" name="email" id="email" />
          </label>
          <label>
            Subject
            <input type="text" name="subject" id="subject" />
          </label>
          <label>
            Message
            <textarea name="message" id="message" rows="5" />
          </label>
          <div className={ styles.btn }>
          <button type="submit">Send</button>
          <Link className={styles.btn}>My Portfolio Projects</Link>
          <input type="reset" value="Clear" />
          </div>
        </form>
      </div>
    </Layout>
    )
}
